import React, { useMemo } from 'react';
import cx from 'classnames';
import { Shipping, ShippingMethodsType, ShippingOption } from 'legacy-types/shipping';
import { buildAddressString } from 'pages/packages/checkout/steps-wizard/steps/shipping-details/helpers/build-address-string';
import { DifferentAddressCTA } from 'pages/packages/checkout/steps-wizard/steps/shipping-details/different-address-cta';
import { produce } from 'immer';
import { useTranslation } from 'react-i18next';
import { Badge } from '@design-system/feedback/badge';
import { AccommodationType } from 'legacy-types/accommodation';

type ShippingMethodsProps = {
  shipTo?: AccommodationType;
  accommodationName?: string;
  shippigDetails?: ShippingOption | null;
  shipping: Shipping;
  setShipping: (method: Shipping) => void;
  config?: { hideCollect?: boolean; hideShip?: boolean };
};

export const ShippingMethods = ({
  config,
  shipTo,
  shipping,
  setShipping,
  shippigDetails,
  accommodationName
}: ShippingMethodsProps) => {
  const { t } = useTranslation();

  const accounts = useMemo(
    () => [
      {
        id: 'collect',
        disabled: config?.hideCollect,
        hidden: config?.hideCollect,
        name: t('checkoutPage.form.shipping.collection'),
        description: (
          <div>
            <div>
              {t('checkoutPage.form.shipping.collectAt', {
                context: shipTo,
                accommodation: accommodationName
              })}
            </div>
          </div>
        )
      },
      {
        id: 'ship',
        name: t('checkoutPage.form.shipping.shipTo', { name: shipping.name }),
        disabled: true,
        hidden: config?.hideShip,
        description: (
          <div>
            <div className="space-x-1">
              {shipping.address ? buildAddressString(shipping.address) : null}
            </div>
            <DifferentAddressCTA
              onSubmit={(address) =>
                setShipping(
                  produce(shipping, (draft) => {
                    draft.address = address;
                    if (shipping.shippingMethod === 'ship') {
                      draft.uuid = shippigDetails?.uuid;
                    }
                  })
                )
              }
            />
          </div>
        )
      }
    ],
    [
      config?.hideCollect,
      config?.hideShip,
      t,
      shipTo,
      accommodationName,
      shipping,
      shippigDetails?.price,
      shippigDetails?.uuid,
      setShipping
    ]
  );

  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">
        {t('checkoutPage.form.shipping.shippingMethod')}
      </h2>
      {shipTo === 'camping' ? (
        <div className="py-2 text-gray-600">{t('checkoutPage.form.shipping.description')}</div>
      ) : null}
      <fieldset className="mt-2">
        <div className="divide-y divide-gray-300">
          {accounts
            .filter((acc) => !acc.hidden)
            .map((account, accountIdx) => (
              <div key={accountIdx} className="relative flex items-start pb-4 pt-3.5">
                <div className="ml-3 flex h-6 items-center mr-6">
                  <input
                    disabled={account.disabled}
                    checked={account.id === shipping.shippingMethod}
                    id={`account-${account.id}`}
                    aria-describedby={`account-${account.id}-description`}
                    name="account"
                    type="radio"
                    onClick={() =>
                      setShipping(
                        produce(shipping, (draft) => {
                          draft.shippingMethod = account.id as ShippingMethodsType;
                          if (account.id === 'ship') {
                            draft.uuid = shippigDetails?.uuid;
                          }
                        })
                      )
                    }
                    defaultChecked={account.id === shipping.shippingMethod}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`account-${account.id}`}
                    className={cx(
                      'font-medium',
                      account.disabled ? 'text-gray-500' : 'text-gray-900'
                    )}>
                    {account.name}
                  </label>
                  {account.id === 'ship' && account.disabled ? (
                    <div>
                      <Badge
                        value={t('checkoutPage.form.shipping.deliveryNotSupported')}
                        color="warning"
                      />
                    </div>
                  ) : (
                    <p id={`account-${account.id}-description`} className="text-gray-600">
                      {account.description}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </fieldset>
    </>
  );
};
