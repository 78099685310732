import React, { FC, ReactNode } from 'react';
import { formatDate } from 'pages/packages/shop/helpers/format-date';
import { getI18n, useTranslation } from 'react-i18next';
import { JOURNEY_TYPE } from 'pages/packages/constants';
import { JourneyDetail, JourneyType } from 'legacy-types/journeys';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import intervalToDuration from 'date-fns/intervalToDuration';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusSimple, faPlane } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';

const JourneyItinerary: FC<{
  journey: JourneyDetail;
  type: JourneyType;
  children?: ReactNode;
  print?: boolean;
}> = (props) => {
  const Icon = props.type === 'flight' ? faPlane : faBusSimple;
  const t = useTranslation().t;
  const locale = getI18n().language;
  const isFlightType = props.type === JOURNEY_TYPE.FLIGHT;

  // Convert the datetime strings to the same reference timezone (e.g., UTC) to accurately calculate the difference
  const date1Utc = zonedTimeToUtc(props.journey.departure.date, props.journey.departure.timeZone);
  const date2Utc = zonedTimeToUtc(props.journey.arrival.date, props.journey.arrival.timeZone);

  const duration = intervalToDuration({
    start: 0,
    end: differenceInSeconds(date2Utc, date1Utc) * 1000
  });

  const durationStirng = `${duration.hours}h ${duration.minutes}m`;

  const DurationLine = () => (
    <div className="px-4 md:px-0 sm:w-full flex justify-center align-middle items-center gap-4">
      <div className="hidden md:block flex-grow border-t border-gray-300"></div>
      <div className="hidden md:block items-center text-xs text-gray-600">
        <span className={cn('text-gray-600', props.print ? 'text-2xl' : 'text-sm')}>
          {durationStirng}
        </span>
      </div>
      <div className="hidden md:block flex-grow border-t border-gray-300 relative">
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <FontAwesomeIcon className="h-4 w-4 text-pink-800" icon={Icon} />
        </div>
      </div>
      <div className="md:hidden grow-0 justify-items-center">
        <FontAwesomeIcon className="h-4 w-4 text-pink-800" icon={Icon} />
      </div>
    </div>
  );

  const JourneyInfo = () => (
    <div
      className={cn(
        'align-middle items-center gap-4',
        props.print
          ? 'text-2xl grid grid-cols-3 justify-between'
          : 'flex  text-sm sm:grid sm:grid-cols-[200px_auto_200px]'
      )}>
      <div className="flex flex-col items-start justify-self-start text-left">
        <span className="font-medium text-gray-800">{props.journey.departure.name}</span>
        <div className="text-gray-600">
          {formatDate(props.journey.departure.date, locale, true)}
        </div>
        <div className="text-gray-600 pb-2">{props.journey.departure.timeZone}</div>
      </div>
      <DurationLine />
      <div className="flex flex-col items-end justify-self-end text-right">
        <span className="font-medium text-gray-800">{props.journey.arrival.name}</span>
        <div className="text-gray-600">{formatDate(props.journey.arrival.date, locale, true)}</div>
        <div className="text-gray-600 pb-2">{props.journey.arrival.timeZone}</div>
      </div>
    </div>
  );

  return (
    <div className="cursor-pointer mx-auto bg-gray-100 shadow hover:shadow-md rounded-lg p-4 w-full">
      <div className="w-full items-center align-middle gap-1">
        <JourneyInfo />
      </div>
      <div className="md:hidden flex justify-between items-center align-middle gap-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <div className="items-center">
          <span className={cn('text-gray-600', props.print ? 'text-2xl' : 'text-sm')}>
            {durationStirng}
          </span>
        </div>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      {isFlightType ? (
        <div className={cn('mt-4 pb-4', props.print ? 'text-2xl' : 'text-sm')}>
          <div className="text-left mt-2">
            <span className="text-gray-800 font-bold">
              {t('packageBuilder.steps.journeyItinerary.flightOperatedBy', {
                company: props.journey.operator.toUpperCase()
              })}
            </span>
          </div>{' '}
          <div className="text-left mt-2">
            <span className="text-gray-800 font-bold">
              {t('packageBuilder.steps.journeyItinerary.flightNumber', {
                flightNumber: props.journey.operatorTripNumber
              })}
            </span>
          </div>
          <div className="text-left mt-1 flex flex-col gap-1">
            <span className="text-gray-600">
              {t(`common.ticket_fare.${props.journey.ticketFare}`)}
              {' — '}
              {t('packageBuilder.steps.journeyItinerary.directFlight')}
            </span>
          </div>
        </div>
      ) : null}
      {props.children ? <div className="text-indigo-500 text-sm pt-2">{props.children}</div> : null}
    </div>
  );
};

export default JourneyItinerary;
