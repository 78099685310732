import React, { ErrorInfo, ReactNode, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SiteHeader from 'containers/SiteHeader';
import { PackageShop } from 'pages/packages/shop/package-shop';
import Checkout from 'pages/packages/checkout/index';
import LandingPage from 'pages/landing-page/landing-page';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { OrderStatus } from 'pages/order/order-status';
import { ErrorBoundaryFallback } from 'pages/commom/components/error-boundary-fallback'; //
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { track } from '@common/analytics/use-analytics';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';
import { useTranslation } from 'react-i18next';
import { ConsumerAuthContainer } from 'ConsumerAuthContainer';
import PackagesSection from 'pages/landing-page/packages-section';
import { useFeatureFlag } from 'configcat-react';

const logError = (error: Error, info: ErrorInfo) => {
  void track('PAGE_ERROR', 'page', {
    error: error?.toString(),
    errorInfo: info?.componentStack?.toString()
  });
};

const MainPageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="min-h-screen global-journey text-base text-neutral-900 dark:text-neutral-200 bg-black flex flex-col h-screen">
      <SiteHeader />
      {children}
    </div>
  );
};

const MyRoutes = () => {
  const { t } = useTranslation();
  const { fetchInventory } = useStepsFlow((s) => ({
    fetchInventory: s.fetchInventory
  }));

  const { value: shopClosed, loading: loadingFlags } = useFeatureFlag('shopClosed', false);

  const { isFetching } = useQuery(
    ['inventory', 'packages'],
    async () => {
      return fetchInventory();
    },
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    useStepsFlow.setState({ isFetchingInventory: isFetching });
  }, [isFetching]);

  // https://developers.intercom.com/installing-intercom/web/methods/
  // @ts-ignore
  window.Intercom('hide', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'vlxohj1t',
    custom_launcher_selector: '#intercom-launcher'
  });

  const Loading = () => (
    <EmptyStateContainer
      isLoading
      title={t('loadingStates.loading.title')}
      description={t('loadingStates.loading.description')}
    />
  );

  return (
    <BrowserRouter basename="/">
      <ErrorBoundary onError={logError} fallback={<ErrorBoundaryFallback />}>
        <Routes>
          <Route
            path={`/`}
            element={
              <MainPageContainer>
                <ConsumerAuthContainer>
                  <LandingPage>
                    <PackagesSection />
                  </LandingPage>
                </ConsumerAuthContainer>
              </MainPageContainer>
            }
          />
          <Route
            path={`packages/shop/:type/:step?`}
            element={
              shopClosed ? (
                <Navigate to="/" />
              ) : (
                <MainPageContainer>
                  <ConsumerAuthContainer>
                    <PackageShop />
                  </ConsumerAuthContainer>
                </MainPageContainer>
              )
            }
          />
          <Route
            path={`packages/checkout/:checkoutHash`}
            element={shopClosed ? <Navigate to="/" /> : <Checkout />}
          />
          <Route
            path={`order/status/:orderId`}
            element={
              isFetching || loadingFlags ? (
                <Loading />
              ) : (
                <MainPageContainer>
                  <OrderStatus />
                </MainPageContainer>
              )
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default MyRoutes;
