import React from 'react';
import { Helmet } from 'react-helmet';
import Logo from 'shared/Logo/Logo';
import LangDropdown from 'components/Header/LangDropdown';
import { ContactUs } from 'components/contact-us';

const SiteHeader = () => {
  return (
    <div className="no-print">
      <Helmet>
        <title>Global Journey - Tomorrowland Brasil</title>
      </Helmet>
      <div
        className={`bg-black fixed h-[40px] top-0 w-full left-0 right-0 z-40 shadow-gray-900 shadow-sm flex items-center text-gray-300 justify-between`}>
        <div className="flex gap-1 align-middle">
          <div className="px-3" onClick={() => (window.location.href = '/')}>
            <Logo maxH="max-h-4" />
          </div>
          <div className="h-6 self-center border-gray-800 border-r"></div>
        </div>
        <div className="flex items-center divide-x divide-gray-800">
          <div className="justify-end inline-flex items-center text-gray-300 px-3">
            <LangDropdown />
          </div>
          <div className="items-center font-bold text-sm px-4">
            <a
              href="https://tomorrowlandbrasil.zendesk.com/hc"
              target="_blank"
              className="self-center"
              rel="noreferrer">
              FAQ
            </a>
          </div>
          <div className="items-center font-bold text-sm px-4">
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteHeader;
